.login-background
{
  background-image: url("./../../../assets/images/jen-and-family.png");
  background-position: 70%;
  background-repeat: no-repeat;
  background-size: cover;

  &.travel-lite {
    background-image: url("./../../../assets/images/jen-and-family.png") !important;
  }
}

.login-container
{
  width: 100%;
  max-width: 650px;
  min-width: 350px;
}

.trial-container
{
  width: 100%;
  max-width: 575px;
  min-width: 350px;
}

.benefits-list
{
  li{
    border: 0 !important;
    p {
      font-size: 18px;
    }
  }
}